import React, { Fragment } from "react";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";
import CommandBus from "../data/CommandBus";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { useGlobal } from "reactn";
import { views, navigateTo } from "../navigation/Navigator";

const SitePage = () => {
  const [currentSite] = useGlobal("currentSite");
  const [mainView, setMainView] = useGlobal("mainView");
  return (
    <Fragment>
      <div>
        <Fragment>
          <span>
            {currentSite}
          </span>
          <Divider />
          <List>
            <ListItem>
              <ListItemText>
                Her kommer det masse info + statistikk.
              </ListItemText>
            </ListItem>
            <Divider />
          </List>
        </Fragment>
      </div>
      <Button
        style={{ position: "absolute", bottom: 20, right: 20 }}
        variant="fab"
        color="primary"
        onClick={() => {
          CommandBus.createNewReport(currentSite);
          navigateTo(mainView, setMainView, views.inpectionPage);
        }}
      >
        <Add />
      </Button>
    </Fragment>
  );
};

export default SitePage;
