import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const stupidDelayTime = 500;

class ProgressButton extends Component {
  state = {
    loading: false,
    error: false,
    errorMessage: null
  };

  loadData() {
    this.setState({ loading: true, error: false, errorMessage: null }, () =>
      setTimeout(
        () =>
          this.props
            .action()
            .then(resp => resp.json())
            .then(json => {
              this.setState({
                loading: false,
                error: false,
                errorMessage: null
              });
              this.props.onSuccess(json);
            })
            .catch(message => {
              this.setState({
                loading: false,
                error: true,
                errorMessage: JSON.stringify(message.message)
              });
              //this.props.onError(message);
            }),
        stupidDelayTime
      )
    );
  }

  renderButton() {
    return (
      <Button
        color="secondary"
        variant="contained"
        onClick={() => this.loadData()}
      >
        {this.props.buttonText}
      </Button>
    );
  }

  renderButtonAndError() {
    return (
      <Fragment>
        {this.renderButton()}
        <span>
          Error: {this.state.errorMessage}
        </span>
      </Fragment>
    );
  }

  renderButtonAndProgress() {
    return (
      <Fragment>
        {this.renderButton()}
        <CircularProgress style={{ marginLeft: 10 }} size={30} />
      </Fragment>
    );
  }

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        {this.state.loading
          ? this.renderButtonAndProgress()
          : !this.state.error
            ? this.renderButton()
            : this.renderButtonAndError()}
      </div>
    );
  }
}

export default ProgressButton;
