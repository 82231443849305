import { getToken } from "./Settings";

const rootUrl = process.env.REACT_APP_ROOT_URL;
const defaultAccessToken = process.env.REACT_APP_ACCESS_TOKEN;

const urls = {
  siteList: "inspection/sitelist",
  schema: "inspection/schema/current",
  uploadReport: "command/inspection-report/upload",
  uploadImprovements: "command/inspection-report/improvements",
  uploadImages: "resources/upload" // uten accessToken!!!
};

export const fullUrl = store =>
  rootUrl +
  "/" +
  urls[store] +
  "?access_token=" +
  (getToken() || defaultAccessToken);

/*** 

  // ----------------------------------------------
    // Upload report
    // ---------------------------------------------
    uploadReportDataUrl : function(){
      return constructUrl("command/inspection-report/upload");
  },
  uploadImprovementsUrl : function(){
    return constructUrl("command/inspection-report/improvements");
  },
  uploadReportImageUrl : function(){
    return constructUrlUtenAccessToken("resources/upload");
  },
  portalReportUrl : function(tenantId, reportId){
    var root = getDevSetting().adminPanelUrl;
    return root + "reports/" + tenantId + "/visit/" + reportId + "/";
  },


  **/
