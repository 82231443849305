import { inspection } from "./Store";
import { defaultInspection } from "../domain/inspection";
import reportUpload from "../data/reportUpload";

export const updatePoint = (siteId, pointId, pointChange) => {
  let insp = { ...defaultInspection, ...inspection.get(siteId) };
  insp.inspectionPoints[pointId] = {
    ...pointChange
  };
  inspection.save(siteId, insp);
};

const CommandBus = {
  updatePoint,
  addSiteToVisitList: siteId => inspection.save(siteId, defaultInspection()),
  removeSiteFromVisitList: siteId => inspection.remove(siteId),
  uploadReport: reportUpload,
  createNewReport: siteId => inspection.save(siteId, defaultInspection())
};

export default CommandBus;
