import React from "react";
import SiteList from "../inspection/SiteList";
import Settings from "../Settings";
import SitePage from "../inspection/SitePage";
import VisitSite from "../inspection/VisitSite";
import ZoneReporting from "../inspection/ZoneReporting";

export const navigateTo = (currentState, stateChangeFunc, newPage) => {
  stateChangeFunc(currentState.concat([newPage]));
};

export const views = {
  frontPage: "front",
  settingsPage: "setting",
  sitePage: "site",
  inpectionPage: "inspection",
  zoneReportingPage: "zoneReporting"
};

export const componentForView = view => {
  const mapping = {
    front: <SiteList />,
    setting: <Settings />,
    site: <SitePage />,
    inspection: <VisitSite />,
    zoneReporting: <ZoneReporting />
  };
  return mapping[view];
};
