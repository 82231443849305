import React, { Fragment } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SettingsIcon from "@material-ui/icons/Settings";
import { useGlobal } from "reactn";
import { navigateTo, views } from "../navigation/Navigator";

const SideMenu = () => {
  const [showSidebar, setShowSidebar] = useGlobal("showSidebar");
  const [mainView, setMainView] = useGlobal("mainView");

  const closeMe = () => setShowSidebar(false);
  return (
    <SwipeableDrawer
      open={showSidebar}
      onOpen={() => setShowSidebar(true)}
      onClose={() => closeMe()}
    >
      <div
        onKeyDown={() => closeMe()}
        tabIndex={0}
        role="button"
        onClick={() => closeMe()}
      >
        <List>
          <Fragment key={1}>
            <ListItem>
              <ListItemIcon>
                <ListItemText primary="Checklist" secondary="v2.0.0" />
              </ListItemIcon>
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() => {
                closeMe();
                navigateTo(mainView, setMainView, views.settingsPage);
              }}
            >
              <ListItemIcon>
                <SettingsIcon />
                <ListItemText primary="Innstillinger" />
              </ListItemIcon>
            </ListItem>
          </Fragment>
        </List>
      </div>
    </SwipeableDrawer>
  );
};

export default SideMenu;
