import React, { Fragment } from "react";
import "./App.css";
import ClAppBar from "./common/ClAppBar";
import SideMenu from "./inspection/SideMenu";
import { useGlobal } from "reactn";
import { componentForView } from "./navigation/Navigator";

const App = () => {
  const [mainView] = useGlobal("mainView");
  return (
    <Fragment>
      <SideMenu />
      <ClAppBar />
      <div id="content">
        <div>
          {JSON.stringify(mainView)}
        </div>
        <div>
          {"current: " + JSON.stringify(mainView[mainView.length - 1])}
        </div>
        {componentForView(mainView[mainView.length - 1])}
      </div>
    </Fragment>
  );
};

export default App;
