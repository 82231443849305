import React from "react";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBack from "@material-ui/icons/ArrowBack";

import { useGlobal } from "reactn";

const ClAppBar = () => {
  const [, setShowSidebar] = useGlobal("showSidebar");
  const [searchText, setSearchText] = useGlobal("searchText");
  const [mainView, setMainView] = useGlobal("mainView");

  const navigateBack = () => {
    setMainView(mainView.slice(0, -1));
  };

  const barText = "Checklist";
  return (
    <AppBar position="static">
      <Toolbar style={{ justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <IconButton>
            <MenuIcon
              style={{ color: "white" }}
              onClick={() => setShowSidebar(true)}
            />
          </IconButton>
          {mainView.length > 1
            ? <Button style={{ color: "white" }} onClick={() => navigateBack()}>
                <ArrowBack />
              </Button>
            : null}
          <Typography variant="h6" color="inherit" noWrap>
            {barText}
          </Typography>
        </div>
        <div
          style={{
            marginLeft: 10,
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <InputBase
            style={{ color: "white", textAlign: "right" }}
            placeholder="Search…"
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
          <div>
            <SearchIcon />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};
export default ClAppBar;
