import { fullUrl } from "./UrlManager";
import Store from "./Store";

const uploadReport = siteId => {
  const reportData = JSON.stringify(Store.inspection.get(siteId));
  fetch(fullUrl("uploadReport"), {
    method: "POST",
    body: reportData,
    mode: "no-cors"
  })
    .then(data => console.log("ferdig"))
    .catch(error => console.log("error"));
};

export default uploadReport;
