import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { views } from "./navigation/Navigator";

import { setGlobal } from "reactn";

setGlobal({
  showSidebar: false,
  searchText: "",
  mainView: [views.frontPage],
  currentSite: null,
  currentZone: null
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
