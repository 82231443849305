import { fullUrl } from "./UrlManager";

const standardOption = {
  method: "GET",
  headers: new Headers({ "Content-Type": "text/plain" })
};

const fetchTheStore = store => fetch(fullUrl(store), standardOption);
const saveTheStore = (store, data) =>
  localStorage.setItem(store, JSON.stringify(data));
const getTheStore = store => JSON.parse(localStorage.getItem(store));

// ---------------------------------------------------------------
// siteList
// ---------------------------------------------------------------

export const siteList = {
  fetch: () => fetchTheStore("siteList"),
  save: siteList => saveTheStore("siteList", siteList),
  get: () => getTheStore("siteList") || []
};

// ---------------------------------------------------------------
// VisitList
// ---------------------------------------------------------------

const selectedSites = () =>
  Object.keys(localStorage)
    .filter(k => k.startsWith("inspectionfor_"))
    .map(i => i.substr(14));

export const visitList = {
  get: () => selectedSites()
};

// ---------------------------------------------------------------
// schema
// ---------------------------------------------------------------
export const schema = {
  fetch: () => fetchTheStore("schema"),
  save: schema => saveTheStore("schema", schema),
  get: () => getTheStore("schema") || {}
};

// ---------------------------------------------------------------
// inspection
// ---------------------------------------------------------------

export const inspection = {
  save: (siteId, inspection) =>
    saveTheStore("inspectionfor_" + siteId, inspection),
  get: siteId => getTheStore("inspectionfor_" + siteId) || {},
  remove: siteId => localStorage.removeItem("inspectionfor_" + siteId)
};

// ---------------------------------------------------------------

export const Store = {
  siteList,
  schema,
  inspection
};

export default Store;
