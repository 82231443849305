import React, { Fragment } from "react";
import { siteList } from "../data/Store";
import Avatar from "@material-ui/core/Avatar";
import deepOrange from "@material-ui/core/colors/deepOrange";
import deepPurple from "@material-ui/core/colors/deepPurple";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ChevronRight } from "@material-ui/icons/";
import { useGlobal } from "reactn";
import { views, navigateTo } from "../navigation/Navigator";

const SiteList = () => {
  const [searchText] = useGlobal("searchText");
  const [mainView, setMainView] = useGlobal("mainView");
  const [, setCurrentSite] = useGlobal("currentSite");

  return (
    <Fragment>
      <List>
        {siteList
          .get()
          //.filter(i => visitList.get().includes(i.siteId))
          .filter(i =>
            i.siteName.toLowerCase().includes(searchText.toLowerCase())
          )
          .map((site, i) =>
            <Fragment key={i}>
              <ListItem
                button
                onClick={() => {
                  setCurrentSite(site.siteId);
                  navigateTo(mainView, setMainView, views.sitePage);
                }}
              >
                <Avatar
                  style={{
                    backgroundColor: i % 2 ? deepOrange[500] : deepPurple[500]
                  }}
                >
                  {site.siteName.substr(0, 1)}
                </Avatar>
                <ListItemText primary={site.siteName} secondary="5. januar" />
                <ChevronRight />
              </ListItem>
              <Divider />
            </Fragment>
          )}
      </List>
    </Fragment>
  );
};

export default SiteList;
