import React, { Fragment, useState } from "react";
import ProgressButton from "./common/ProgressButton";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";

import { schema, siteList } from "./data/Store";
import { getToken, setToken } from "./data/Settings";

const Settings = () => {
  const [token, setTheToken] = useState(getToken());

  const changeToken = t => {
    setToken(t);
    setTheToken(t);
  };

  return (
    <Fragment>
      <Card style={{ margin: 10 }}>
        <CardContent>
          <TextField
            label="Token"
            value={token}
            onChange={e => changeToken(e.target.value)}
            margin="normal"
          />
        </CardContent>
      </Card>
      <Card style={{ margin: 10 }}>
        <CardContent>
          <ProgressButton
            buttonText="Hent skjemadefinisjon"
            action={schema.fetch}
            onSuccess={data => schema.save(data)}
          />
          <p />
          <ProgressButton
            buttonText="Hent butikkliste"
            action={siteList.fetch}
            onSuccess={data => siteList.save(data)}
          />
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default Settings;
