import React, { Fragment } from "react";
import { useState } from "react";

import { inspection } from "../data/Store";
import { schema } from "../data/Store";
import { updatePoint } from "./../data/CommandBus";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import deepOrange from "@material-ui/core/colors/deepOrange";
import deepPurple from "@material-ui/core/colors/deepPurple";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {
  SentimentSatisfied,
  SentimentVerySatisfied,
  SentimentDissatisfied
} from "@material-ui/icons/";
import { useGlobal } from "reactn";

const ZoneReport = () => {
  const state = { in: true, impPointSwitch: { "01": true } };

  const [, useForceUpdate] = useState({});

  const toggleImpSwith = pointId => {
    let nP = { ...this.state.impPointSwitch };
    nP[pointId] = !nP[pointId];
    //this.setState({ impPointSwitch: nP });
  };

  const handleImprovementChange = event => {
    console.log(event.target.value);
  };

  const renderscoreButton = (siteId, pointId, point, scoreButton) => {
    let color =
      scoreButton === 2 ? "green" : scoreButton === 1 ? "yellow" : "red";

    return (
      <Button
        key={pointId + scoreButton}
        style={{
          backgroundColor: color,
          margin: 3,
          borderRadius: 4,
          borderWidth: 4,
          borderStyle: (point || {}).score === scoreButton ? "solid" : "none"
        }}
        variant={(point || {}).score === scoreButton ? "contained" : "text"}
        onClick={() => {
          updatePoint(siteId, pointId, { score: scoreButton });
          useForceUpdate();
        }}
      >
        {scoreButton === 2
          ? <SentimentVerySatisfied />
          : scoreButton === 1
            ? <SentimentSatisfied />
            : <SentimentDissatisfied />}
      </Button>
    );
  };

  const renderPointResult = (siteId, pointId, pointData, impPointSwitch) => {
    return (
      <div style={{ marginTop: 40 }}>
        {[2, 1, 0].map(i => renderscoreButton(siteId, pointId, pointData, i))}
        <div>
          <p />
          <Typography variant="h6">Kommentar</Typography>
          <TextField
            placeholder="Hello World"
            multiline
            rowsMax="6"
            rows="4"
            style={{ width: "100%" }}
          />
          <Switch
            color="primary"
            checked={impPointSwitch[pointId] || false}
            onChange={() => toggleImpSwith(pointId)}
          />Forbedringer
          <p />
          <div>
            <Typography>Forbedringer</Typography>
            <TextField
              id="name"
              label="Name"
              value={"this.state.name"}
              onChange={e => handleImprovementChange(e)}
              margin="normal"
            />
          </div>
        </div>
      </div>
    );
  };

  const [currentSite] = useGlobal("currentSite");
  const [currentZone] = useGlobal("currentZone");

  const { siteId, zoneId } = { siteId: currentSite, zoneId: currentZone };

  if (!siteId || !zoneId) return null;
  const zone = schema.get().zones[zoneId];
  const allPoints = zone.inspectionPoints;
  const pointIndex = zone.pointOrder;
  const insp = inspection.get(siteId);
  return (
    <div>
      <Fragment>
        {/* <Slide direction="left" in={this.state.in} mountOnEnter unmountOnExit> */}
        <Fragment>
          {pointIndex.map((pointId, i) =>
            <Card key={i} style={{ margin: 10 }}>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  <Avatar
                    style={{
                      backgroundColor: i % 2 ? deepOrange[500] : deepPurple[500]
                    }}
                  >
                    {allPoints[pointId].name.substr(0, 1)}
                  </Avatar>

                  <Typography style={{ margin: 20 }} variant="h5">
                    {allPoints[pointId].name}
                  </Typography>
                </div>
                <Divider />
                <Typography style={{ margin: 20 }} variant="body1">
                  {allPoints[pointId].instruction}
                </Typography>
                <Divider />
                {renderPointResult(
                  siteId,
                  pointId,
                  insp.inspectionPoints[pointId],
                  state.impPointSwitch
                )}
              </CardContent>
            </Card>
          )}
        </Fragment>
        {/* </Slide> */}
      </Fragment>
    </div>
  );
};

export default ZoneReport;
